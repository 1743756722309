html,
body * {
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

html,
body {
  height: 100%;
}

#root {
  height: 100%;
}

.app {
  height: 100%;
}

.app-header {
  background: #005c97; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #363795,
    #005c97
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #363795,
    #005c97
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
}

.stake-card {
  width: 430px;
  max-width: 95%;
  background: linear-gradient(rgba(35, 43, 85, 0.75), rgba(35, 43, 85, 0.95));
  background-size: cover;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: all 0.5s ease;
}

.select-style {
  width: 100%;
  height: 35px;
  padding-left: 15px;
  border: none;
  border-radius: 20px;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.2);
}

.input-style {
  width: 100%;
  height: 35px;
  padding-left: 15px;
  border: none;
  border-radius: 20px;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.2);
}

:focus {
  outline: none;
}

.stake-button {
  float: left;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 13px;
  text-align: center;
  color: #ffffff;
  width: 100%;
  height: 35px;
  border: none;
  border-radius: 20px;
  margin-top: 23px;
  background-color: #1059ff;
}

.mode-button {
  float: left;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 10px;
  text-align: center;
  color: black;
  width: auto;
  margin-left: 50%;
  transform: translate(-50%, 0);
  height: 35px;
  border: none;
  border-radius: 20px;
  margin-top: 23px;
  background-color: #ffffff;
  cursor: pointer;
}

.help {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 95px;
  padding-top: 24px;
  border-top: solid 1px rgba(255, 255, 255, 0.3);
  transition: all 0.5s ease;
}

.help a {
  color: rgba(255, 255, 255, 0.404);
  font-weight: 400;
  text-decoration: none;
}

.stake-button:hover {
  cursor: pointer;
  background-color: #0f4fe6;
  transition: background-color 0.5s;
}

.form-container {
  width: 430px;
  max-width: 100%;
  font-size: 16px;
  font-weight: 300;
  padding-left: 37px;
  padding-right: 37px;
  padding-top: 55px;
  padding-bottom: 50px;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

label {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 13px;
  padding-left: 15px;
  padding-bottom: 10px;
  color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: space-between;
}

input,
select {
  color: #ffffff;
  font-size: 13px;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
}

option {
  color: gray;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 0.4; /* Firefox */
}

.republic-logo {
  height: 45px;
  margin-top: 35px;
  margin-left: 50%;
  transform: translate(-50%, 0);
}

.switch-modes {
  cursor: pointer;
  font-size: 14px;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  margin: 0px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
